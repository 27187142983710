import { computed, onBeforeMount, ref } from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

import store from '@/store'

import storeModule from '@train/trainStoreModule'

import useToast from '@useToast'

export default function useTrainTicketDetailHandle() {
  if (!store.hasModule('app-train')) {
    store.registerModule('app-train', storeModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }

  const stateLoadingCategory = computed(() => store.state['app-train'].loadingCategory)
  const stateCategory = computed(() => store.state['app-train'].category)
  const stateLoading = computed(() => store.state['app-train'].loading)

  const getListTrainStation = computed(() => store.getters['app-train/getListTrainStation']) // (array) list trạm dừng
  const getListPassengerTarget = computed(() => store.getters['app-train/getListPassengerTarget']) // (array) list

  const bookingData = computed(() => store.getters['app-train/getBookingData'])

  onBeforeMount(() => {
    if (!stateCategory.value || !getListTrainStation.value) {
      store.dispatch('app-train/handleGetCategory')
    }
    // if (!stateSetting.value) {
    //   store.dispatch('app-train/handleGetSetting')
    // }
  })
  const { toastError, toastSuccess } = useToast()

  const getBookingById = bookingId => new Promise((resolve, reject) => {
    store
      .dispatch('app-train/getBookingById', bookingId)
      .then(res => resolve(res.data))
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Tải chi tiết vé thất bại',
        })
      })
  })

  const refetchBookingData = () => {
    getBookingById(bookingData.value.id)
  }

  const disabledCopy = ref(false)

  const { copy } = useClipboard()
  function copyText(text) {
    disabledCopy.value = true
    setTimeout(() => {
      disabledCopy.value = false
    }, 1000)

    copy(text)
      .then(() => {
        this.$root.toastSuccess('reservation.sms.copySuccess')
      })
      .catch(() => {
        this.$root.toastError('reservation.sms.copyFail')
      })
  }

  return {
    stateLoading,
    stateLoadingCategory,
    getListPassengerTarget,
    disabledCopy,
    bookingData,

    getBookingById,
    refetchBookingData,
    copyText,
  }
}
